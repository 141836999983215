require('dotenv').config({
  path: `.env.${process.env.NODE_ENV}`
})
let projectId = process.env.PROJECT_ID
let dataset = process.env.DATASET
if (!process.env.PROJECT_ID) {
  projectId = process.env.GATSBY_PROJECT_ID
  dataset = process.env.GATSBY_DATASET
}

export default {
  projectId: projectId,
  dataset: dataset
}
