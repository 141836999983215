import React from 'react'
import styled from 'styled-components'
import { below } from '../styles/abstracts/breakpoints.js'
import googleReviews from '../images/Google-Reviews-transparent-small.png'
import fiveStars from '../images/5-stars.png'
import Swiper from 'react-id-swiper'

const OuterContainer = styled.div`
  background-color: ${props => props.theme.color.white};
  display: flex;
  display: -webkit-flex;
  display: -ms-flexbox;
  flex-wrap: wrap;
  overflow: visible;

  ${below.lg} {
    overflow: hidden;
    flex-direction: column-reverse;
  }
`

const SwiperWrapper = styled.div`
  width: 100%;

  .swiper-container {
    width: 100%;
    height: auto;
  }
  .swiper-slide {
    width: 100%;
  }

  .swiper-pagination-bullet {
    background: ${props => props.theme.color.grey.dark};
  }

  .swiper-pagination-bullet-active {
    background: ${props => props.theme.color.yellow};
  }

  .swiper-button-next {
    color: ${props => props.theme.color.grey.dark};
  }

  .swiper-button-prev {
    color: ${props => props.theme.color.grey.dark};
  }
`

const TrainChampionsColumn1 = styled.div`
  flex: 1;
  padding-bottom: 0em;
  width: 50%;
  display: flex;
  align-items: flex-end;
  overflow-y: visible;
  margin-top: -6em;

  ${below.lg} {
    display: block;
    width: 100%;
    padding-right: 6em;
  }
`

const BJJImage = styled.img`
  display: block;
  top: 0em;
  height: 35rem;
  position: relative;
  margin: 0 auto 0 auto;
`

const TrainChampionsColumn2 = styled.div`
  height: 105%;
  padding: 6em;
  padding-right: 125px;
  max-width: calc(50% - 125px);
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: visible;

  ${below.lg} {
    min-width: calc(100% - 250px);
    padding: 6em;
    padding-left: 125px;
    padding-right: 125px;
  }

  ${below.md} {
    min-width: calc(100% - 40px);
    padding-left: 20px;
    padding-right: 20px;
  }
`

const Text = styled.div`
  flex-wrap: wrap;
  width: 100%;
  color: ${props => props.theme.font.color.grey.medium};
  font-family: ${props => props.theme.font.family.base};
  font-size: 21px;
  font-weight: 300;
  letter-spacing: -0.28px;
  line-height: 2em;
  ${below.md} {
    font-size: 18px;
    line-height: 30px;
  }
`

const ReviewerAndlogoWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  white-space: nowrap;
  overflow: hidden;
  padding-top: 1em;
  padding-bottom: 1em;
  ${below.md} {
    flex-direction: column;
  }
`

const GoogleColumn1 = styled.div`
  flex: 1;
  height: fit-content;
  align-self: baseline;
  width: 100%;
`

const GoogleColumn2 = styled.div`
  flex: 1;
  height: fit-content;
  max-width: 8em;
  min-width: 8em;
`

const HeadShotStyle = styled.img`
  width: 4.5em;
  height: 4.5em;
  margin-right: 1em;
  float: left;
  border-radius: 50%;
`

const ReviewerDetails = styled.div`
  float: left;
`

const ReviewerNameText = styled.div`
  flex-wrap: wrap;
  color: ${props => props.theme.font.color.grey.dark};
  font-family: ${props => props.theme.font.family.base};
  font-size: ${props => props.theme.font.size.base};
  letter-spacing: -0.24px;
  line-height: 1.5em;
`

const TrainingSinceText = styled.div`
  color: ${props => props.theme.font.color.grey.light};
  font-family: ${props => props.theme.font.family.base};
  font-size: 16px;
  letter-spacing: -0.21px;
  line-height: 1.5em;
`

const FiveStarsStyle = styled.img`
  width: 6em;
  margin-top: 0.4em;
`

const GoogleLogo = styled.img`
  width: 7em;
  float: right;
  margin-top: 1em;

  ${below.md} {
    float: none;
    margin-left: 5.5em;
    margin-bottom: 1em;
  }
`

const GoogleReviews = ({ block, block: { featuredImage, reviews, googleReviewURL } }) => {
  const params = {
    autoplay: {
      delay: 10000,
      speed: 900
    },
    pagination: {
      el: '.swiper-pagination',
      clickable: true
    },
    slidesPerView: 1,
    spaceBetween: 125,
    loop: true,
    autoHeight: true
  }

  const featuredTestimonialImage = featuredImage.asset.url

  return (
    <OuterContainer>
      <TrainChampionsColumn1>
        <BJJImage src={featuredTestimonialImage} />
      </TrainChampionsColumn1>
      <TrainChampionsColumn2>
        <SwiperWrapper>
          <Swiper {...params}>
            {reviews.map((review, i) => (
              <div key={i}>
                <Text>{review.body}</Text>
                <ReviewerAndlogoWrapper>
                  <GoogleColumn1>
                    <div>
                      <HeadShotStyle src={review.image.asset.url} alt={review.name} />
                    </div>
                    <ReviewerDetails>
                      <ReviewerNameText>{review.name}</ReviewerNameText>
                      <TrainingSinceText>{review.description}</TrainingSinceText>
                      <FiveStarsStyle src={fiveStars} />
                    </ReviewerDetails>
                  </GoogleColumn1>
                  <GoogleColumn2>
                    <a href={googleReviewURL} target='_blank'>
                      <GoogleLogo src={googleReviews} />
                    </a>
                  </GoogleColumn2>
                </ReviewerAndlogoWrapper>
              </div>
            ))}
          </Swiper>
        </SwiperWrapper>
      </TrainChampionsColumn2>
    </OuterContainer>
  )
}

export default GoogleReviews
