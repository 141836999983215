import React from 'react'
import Accordion from './accordion'
import AllianceSummary from './alliance-summary'
import CallToAction from './call-to-action'
import Contact from './contact'
import ContactForm from './contact-form'
import FAQ from './faq'
import GearPriceInfo from './gear-price-info'
import GoogleReviews from './google-reviews'
import HeroImage from './hero-image'
import ImageCarousel from './image-carousel'
import ImageGallery from './image-gallery'
import InfoBlock1 from './info-block1'
import InfoBlock2 from './info-block2'
import LeadGenForm from './lead-gen-form'
import NewsletterForm from './newsletter-form'
import PriceInfo from './price-info'
import Schedule from './schedule'
import Mat2Schedule from './mat2schedule'
import TextBlock from './text-block'
import TrainChampions from './train-champions'
import TitleBlock from './title-block'
import TwoColumnImageTextBlock from './two-column-image-text-block'
import TwoColumnTextBlock from './two-column-text-block'
import TwoColumnTextImage from './two-column-text-image'
import VideoPlayer from './video-player'
import VideoPlayerTextBlock from './video-text-block'
import ZenKidsFirstClass from './zenplanner-kids-first-class'
import ZenKidsNewsletter from './zenplanner-kids-newsletter'
import ZenNewsletter from './zenplanner-newsletter'

// individual blocks
const renderBlock = block => {
  switch (block._type) {
    case 'kidsClasses':
      return <Accordion key={block._key} block={block} />
    case 'cTA':
      return <CallToAction key={block._key} block={block} />
    case 'contactInfo':
      return <Contact key={block._key} block={block} />
    case 'contactForm':
      return <ContactForm key={block._key} block={block} />
    case 'faq':
      return <FAQ key={block._key} block={block} />
    case 'gearPriceInfo':
      return <GearPriceInfo key={block._key} block={block} />
    case 'hero':
      return <HeroImage key={block._key} block={block} />
    case 'imageGallery':
      return <ImageGallery key={block._key} block={block} />
    case 'imageCarousel':
      return <ImageCarousel key={block._key} block={block} />
    case 'infoBlock1':
      return <InfoBlock1 key={block._key} block={block} />
    case 'infoBlock2':
      return <InfoBlock2 key={block._key} block={block} />
    case 'newsletterForm':
      return <NewsletterForm key={block._key} block={block} />
    case 'priceInfo':
      return <PriceInfo key={block._key} block={block} />
    case 'googleReviews':
      return <GoogleReviews key={block._key} block={block} />
    case 'leadgenForm':
      return <LeadGenForm key={block._key} block={block} />
    case 'scheduleBlock':
      return <Schedule key={block._key} block={block} />
    case 'mat2ScheduleBlock':
      return <Mat2Schedule key={block._key} block={block} />
    case 'summary':
      return <AllianceSummary key={block._key} block={block} />
    case 'textBlock':
      return <TextBlock key={block._key} block={block} />
    case 'titleBlock':
      return <TitleBlock key={block._key} block={block} />
    case 'trainChampions':
      return <TrainChampions key={block._key} block={block} />
    case 'twoColumnImageTextBlock':
      return <TwoColumnImageTextBlock key={block._key} block={block} />
    case 'twoColumnTextBlock':
      return <TwoColumnTextBlock key={block._key} block={block} />
    case 'twoColumnTextImage':
      return <TwoColumnTextImage key={block._key} block={block} />
    case 'videoPlayer':
      return <VideoPlayer key={block._key} block={block} />
    case 'videoTextBlock':
      return <VideoPlayerTextBlock key={block._key} block={block} />
    case 'zenKidsFirstClass':
      return <ZenKidsFirstClass key={block._key} block={block} />
    case 'zenKidsNewsletter':
      return <ZenKidsNewsletter key={block._key} block={block} />
    case 'zenNewsletter':
      return <ZenNewsletter key={block._key} block={block} />
    default:
      return null
  }
}

const ContentBlocks = ({ pageBlocks }) =>
  (pageBlocks && pageBlocks.map(block => renderBlock(block))) || null

export default ContentBlocks
