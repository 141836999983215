/**
 * A component that renders image from the _rawComponentBlock in
 * the sanity API
 */

import React from 'react';
import Image from 'gatsby-image/withIEPolyfill';
import PropTypes from 'prop-types';
import { getFluidGatsbyImage } from 'gatsby-source-sanity';
import sanityConfig from '../utils/sanityClientConfig';

const FluidImageFromRaw = ({
  id,
  maxWidth = 1024,
  wrapperStyle = {},
  imgStyle = {},
  alt = '',
  className,
}) => {
  const fluidProps = getFluidGatsbyImage(id, { maxWidth }, sanityConfig);

  return (
    <Image
      className={className}
      style={wrapperStyle}
      imgStyle={{
        objectFit: 'cover',
        objectPosition: '50% 50%',
        ...imgStyle,
      }}
      fluid={fluidProps}
      alt={alt}
    />
  );
};

FluidImageFromRaw.propTypes = {
  id: PropTypes.string.isRequired,
  maxWidth: PropTypes.number,
};

FluidImageFromRaw.defaultProps = {
  maxWidth: 1024,
};

export default FluidImageFromRaw;
