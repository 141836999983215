import React from 'react'
import styled from 'styled-components'
import { below } from '../styles/abstracts/breakpoints'

const Root = styled.div`
  background-color: ${props => props.backgroundColor.hex};
  display: flex;
  font-family: Source Sans Pro;
  padding-top: 25px;
  padding-bottom: 115px;
  justify-content: center;

  ${below.md} {
    margin: 0px;
    padding: 20px;
    flex-direction: column;
  }
`
const DescriptionContainer = styled.div`
  width: 30%;
  margin-right: 100px;
  ${below.md} {
    width: 95%;
    margin-right: 0px;
    margin-bottom: 30px;
  }
`
const FeatureContainer = styled.div`
  width: 45%;
  display: flex;
  flex-direction: column;
  margin-top: 55px;
  color: #474747;
  font-weight: 300;
  line-height: 30px;

  ul {
    -moz-column-count: 2;
    -moz-column-gap: 20px;
    -webkit-column-count: 2;
    -webkit-column-gap: 20px;
    column-count: 2;
    column-gap: 50px;
  }
  li {
    margin-left: -25px;
  }
  ${below.md} {
    width: 95%;
    margin-top: 0px;
    font-size: 18px;
    line-height: 30px;
  }
`
const HeaderText = styled.div`
  font-size: 38px;
  margin: auto;
  font-weight: bold;
  margin-bottom: 25px;
  letter-spacing: -0.51px;

  ${below.md} {
    margin: 0px;
    margin-bottom: 25px;
  }
`
const BodyText = styled.div`
  font-size: 18px;
  font-weight: 300;
  color: #474747;

  ${below.lg} {
  }
  ${below.md} {
    margin: 0px;
    font-size: 18px;
    line-height: 30px;
  }
`
const InfoBlock2 = ({ block, block: { header, body, program, backgroundColor } }) => {
  const featuresArray = program[0].featureList.split('\n')
  const features = featuresArray.map((feature, i) => {
    return <li key={i}>{feature}</li>
  })
  return (
    <Root backgroundColor={backgroundColor}>
      <DescriptionContainer>
        <HeaderText>{header}</HeaderText>
        <BodyText>{body}</BodyText>
      </DescriptionContainer>
      <FeatureContainer>
        <ul>{features}</ul>
      </FeatureContainer>
    </Root>
  )
}

export default InfoBlock2
