import React, { useRef, useState } from 'react'
import styled, { css } from 'styled-components'
import { above, below } from '../styles/abstracts/breakpoints.js'
import axios from 'axios'
import qs from 'qs'
import { useForm } from 'react-hook-form'

const BlockWrapper = styled.div`
  display: flex;
  background-color: ${props => props.theme.color.white};
  justify-content: space-between;
  ${below.md} {
    flex-direction: column;
  }
`
const FormContainer = styled.div`
  width: 100%;
  padding-top: 10px;
  ${above.lg} {
    margin: auto;
    width: 50%;
  }
`
const TextWrapper = styled.div`
  display: inline-block;
  position: relative;
  font-family: ${props => props.theme.font.family.base};
  flex-direction: column;
  padding-top: ${props => props.reducePadding ? '25px' : '50px'};
  padding-bottom: ${props => props.reducePadding ? '35px' : '125px'};
  margin-left: 50px;
  padding-right: 0px;
  width: calc(100% - 50px);

  ${above.xxl} {
    margin-right: auto;
  }

  @media (max-width: 900px) {
    margin: 0px;
    padding-top: 20px;
    padding-bottom: 81px;
    padding-left: 50px;
    padding-right: 50px;
    max-width: calc(100% - 100px);
    width: calc(100% - 100px);
  }

  ${below.md} {
    margin: 0px;
    padding-top: 0px;
    padding-bottom: 81px;
    margin-left: 20px;
    margin-right: 20px;
    padding-left: 0px;
    padding-right: 0px;
    max-width: calc(100% - 40px);
    width: calc(100% - 40px);
  }
`

const HeaderText = styled.h2`
  font-size: 38px;
  display: block;
  float: left;
  font-weight: bold;
  margin-top: 0px;
  margin-bottom: 25px;
  width: 100%;
  ${below.md} {
    margin: 0px;
    margin-bottom: 25px;
  }
  ${above.lg} {
    margin: auto;
    width: 50%;
  }
`
const HeaderText2 = styled(HeaderText)`
  float: none;
  margin-top: 25px;
  ${above.xl} {
    max-width: 50%;
  }
  ${below.md} {
    margin-top: 25px;
  }
`
const BodyText = styled.div`
  display: block;
  position: relative;
  font-size: 18px;
  font-weight: 300;
  text-align: left;
  width: 100%;
  max-width: 250px;
  float: left;
  letter-spacing: -0.24px;
  line-height: 32px;
  margin-bottom: 15px;

  ${below.lg} {
    width: 75%;
  }
  ${below.md} {
    text-align: left;
    width: 95%;
  }
`

const Button = styled.button`
  background-color: #ffbc00;
  width: 160px;
  height: 40px;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 15px;
  border: none;
  margin-top: 15px;
  text-decoration: none;
  &:hover {
    background-color: rgba(255, 187, 0, 0.89);
  }
  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
    outline: 0;
  }
`

const Label = styled.label`
  position: absolute;
  top: 22px;
  left: 20px;
  transition: 300ms;
  color: black;
  opacity: 0.5;
  font-size: 1.3rem;

  @media ${above.md} {
    top: 22px;
  }
`

const Input = styled.input`
  width: calc(100% - 40px);
  padding: 22px 20px 18px 20px;
  font-size: 1.2rem;
  color: ${props => props.theme.color.grey.dark};
  margin-bottom: 15px;
  &:focus {
    outline-color: ${props => props.theme.color.yellow};
  }

  @media ${below.md} {
    margin-top: 20px;
    margin-bottom: 20px;
  }
`
const InputAnimatedStyles = css`
  ${Label} {
    top: 10px;
    font-size: 1rem;

    @media ${above.md} {
      top: 12px;
    }
  }
  ${Input} {
    padding-top: 30px;
    padding-bottom: 10px;
  }
`

const InputWrapper = styled.div`
  position: relative;
  @media ${above.md} {
    margin-top: 15px;
    margin-bottom: 15px;
  }
  width: 95%;
  ${props => props.animateFields && InputAnimatedStyles}

  &:focus-within {
    ${InputAnimatedStyles};
  }
`

const ErrorMsg = styled.p`
  color: red;
  padding: 5px 0;
  font-size: 1.3rem;
  margin-bottom: 0;
  margin-top: -5px;
  @media ${below.md} {
    margin-top: -20px;
  }
`

const TextArea = styled.textarea`
  position: relative;
  padding: 22px 20px 18px 20px;
  width: 95%;
  font-size: 1.5rem;
  &:focus {
    outline-color: ${props => props.theme.color.yellow};
  }
  @media ${below.md} {
    margin-top: 20px;
    margin-bottom: 20px;
  }
`

const TextAreaAnimatedStyles = css`
  ${Label} {
    top: 12px;
    @media ${above.md} {
      top: 12px;
    }
    transform: translateY(0%);
    font-size: 1.1rem;
  }
  ${TextArea} {
    transform: translateY(0%);
    padding-top: 32px;
  }
`

const TextAreaWrapper = styled.div`
  width: calc(100% - 40px);
  position: relative;
  ${Label} {
    top: 40px;
    transform: translateY(0%);
  }

  @media ${above.md} {
    margin-top: 15px;
    margin-bottom: 15px;

    ${Label} {
      top: 22px;
    }
  }

  ${props => props.animateFields && TextAreaAnimatedStyles}

  &:focus-within {
    ${TextAreaAnimatedStyles};
  }
`
const Message = styled.h2`
  margin-top: 30px;
  margin-bottom: 30px;
  line-height: 1.8;
`
const ContactForm = ({ block, block: { header, bodyText, reducePadding } }) => {
  const [formSubmissionResult, setFormSubmissionResult] = useState(null)
  const formRef = useRef(null)
  const { register, handleSubmit, watch, errors } = useForm({
    submitFocusError: true
  })
  const fieldValues = watch()

  React.useEffect(() => {
    register({ name: 'emailAddress' }, { required: 'Email address is required' })
  }, [])

  const submitForm = (data, e) => {
    e.preventDefault()
    console.log('submitting form')
    // Submit form data
    axios({
      url: '/',
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      data: qs.stringify({
        'form-name': 'contact-form',
        ...data
      })
    })
      .then(() => {
        setFormSubmissionResult('success')
      })
      .catch(error => {
        setFormSubmissionResult('error')
        console.error(error)
      })
    e.target.reset()
  }

  const form = (
    <form
      ref={formRef}
      name='contact-form'
      method='post'
      data-netlify='true'
      data-netlify-honeypot='bot-field'
    >
      <input type='hidden' name='form-name' value='contact-form' />
      <InputWrapper animateFields={!!fieldValues.firstName || errors.firstName}>
        <Input type='text' name='firstName' ref={register({ required: true })} />
        <Label>Name*</Label>
        {errors.firstName && <ErrorMsg>Name is required</ErrorMsg>}
      </InputWrapper>
      <InputWrapper animateFields={!!fieldValues.email || errors.email}>
        <Input
          type='text'
          name='email'
          ref={register({
            required: 'Email is required',
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
              message: 'Invalid email address'
            }
          })}
        />
        <Label>Email*</Label>
        {errors.email && <ErrorMsg>{errors.email.message}</ErrorMsg>}
      </InputWrapper>
      <InputWrapper animateFields={!!fieldValues.phone || errors.phone}>
        <Input
          type='text'
          name='phone'
          ref={register({
            required: 'Phone number is required',
          })}
        />
        <Label>Phone*</Label>
        {errors.phone && <ErrorMsg>{errors.phone.message}</ErrorMsg>}
      </InputWrapper>
      <TextAreaWrapper animateFields={!!fieldValues.message || errors.message}>
        <TextArea name='message' rows='3' ref={register({ required: 'Message is required' })} />
        <Label>Message*</Label>
        {errors.message && <ErrorMsg>{errors.message.message}</ErrorMsg>}
      </TextAreaWrapper>
      <Button onSubmit={handleSubmit(submitForm)} type='submit'>
        SEND
      </Button>
    </form>
  )
  const submittedMessage = success => {
    if (success) {
      return <Message>We have received your message and will be in contact shortly.</Message>
    }
    return (
      <Message>
        Oops. Something has gone wrong with the submission. Please refresh this page and try again,
        or contact us by phone.
      </Message>
    )
  }
  return (
    <BlockWrapper>
      <TextWrapper reducePadding={reducePadding}>
        <HeaderText2>{header}</HeaderText2>
        <BodyText>{bodyText || null}</BodyText>
        <FormContainer>
          {formSubmissionResult ? submittedMessage(formSubmissionResult === 'success') : form}
        </FormContainer>
      </TextWrapper>
    </BlockWrapper>
  )
}

export default ContactForm
