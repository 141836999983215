import { Link } from 'gatsby'
import React from 'react'
import styled from 'styled-components'
import { below } from '../styles/abstracts/breakpoints'
import BlockContent from '../components/block-content'

const SpacerContainer = styled.div`
  display: flex;
  flex-direction: column;
`
const Root = styled.div`
  height: auto;
  padding-top: 25px;
  padding-bottom: 75px;
  background-color: #f4f5f5;
  display: flex;
  align-content: center;
  justify-content: center;
  font-family: Source Sans Pro;
  ${below.lg} {
    padding-top: 65px;
  }
  ${below.md} {
    flex-direction: column;
    height: auto;
    padding-bottom: 45px;
    padding-top: 20px;
  }
`
const DescriptionContainer = styled.div`
  width: 30%;
  margin-right: 100px;

  ${below.md} {
    width: 90%;
    margin-right: 0px;
    margin-left: 20px;
    margin-bottom: 30px;
  }
`

const PriceContainer = styled.div`
  width: 45%;
  display: flex;
  flex-direction: column;
  margin-top: -15px;
  ${below.md} {
    width: 90%;
    margin-left: 20px;
    margin-top: 0px;
  }
`
const HeaderText = styled.h2`
  color: #333333;
  font-weight: bold;
  font-size: 38px;
  letter-spacing: -0.51px;
  line-height: 47px;
  margin-bottom: 30px;
  margin-top: 0px;
`
const Text = styled.div`
  color: #474747;
  font-weight: 300;
  font-size: 18px;
  a {
    color: #ffbd00;
    font-weight: bold;
  }
  ${below.md} {
    font-size: 18px;
    line-height: 30px;
  }
`
const PriceTitleText = styled.div`
  font-weight: bold;
  color: #222222;
  letter-spacing: -0.24px;
  float: left;
  font-size: 18px;
  ${below.md} {
    margin-bottom: 12px;
  }
`
const PriceText = styled(Text)`
  font-weight: 500;
  float: right;
`
const PrivatePriceText = styled(PriceText)`
  margin-top: -40px;

  ${below.md} {
    margin-top: 0px;
  }
`
const PriceRow = styled.div`
  padding-top: 21px;
  padding-bottom: 21px;
  ${below.md} {
    display: flex;
    flex-direction: column;
  }
`

const StyledLink = styled(Link)`
  text-decoration: none;
`

const SignupButton = styled.div`
  margin-top: 30px;
  background-color: #ffbc00;
  width: 160px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: rgba(255, 187, 0, 0.89);
    transition: 0.3s;
  }
`
const ButtonText = styled.div`
  font-weight: 600;
  font-size: 15px;
  color: ${props => props.theme.color.black};
`
const OpaqueLine = styled.div`
  opacity: 0.3;
  border: 0.5px solid #979797;
`
const YellowText = styled.div`
  color: #ffbd00;
  font-size: 16px;
  font-weight: bold;
  text-decoration: underline;
  letter-spacing: -0.24px;
  ${below.md} {
    margin-bottom: 15px;
  }
`
const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 200px;
`
const BackgroundSpacerGrey = styled.div`
  height: 95px;
  background-color: #f4f5f5;
  ${below.lg} {
    height: 65px;
  }
  ${below.md} {
    height: 15px;
  }
`

const GearPriceInfo = ({
  block,
  block: {
    kidsGi,
    kidsRashguard,
    kidsShorts,
    kidsBelt,
    adultGi,
    adultRashguard,
    adultShorts,
    adultBelt,
    header,
    body
  }
}) => {
  return (
    <Root>
      <DescriptionContainer>
        <HeaderText>{header}</HeaderText>
        <Text>
          <BlockContent blocks={body || []} />
        </Text>
      </DescriptionContainer>
      <PriceContainer>
        <PriceRow>
          <PriceTitleText>KIDS' GI</PriceTitleText>
          <PriceText>{kidsGi}</PriceText>
        </PriceRow>
        <OpaqueLine />
        <PriceRow>
          <PriceTitleText>KIDS' RASHGUARD</PriceTitleText>
          <PriceText>{kidsRashguard}</PriceText>
        </PriceRow>
        <OpaqueLine />
        <PriceRow>
          <PriceTitleText>KIDS' SHORTS</PriceTitleText>
          <PriceText>{kidsShorts}</PriceText>
        </PriceRow>
        <OpaqueLine />
        <PriceRow>
          <PriceTitleText>KIDS' BELT</PriceTitleText>
          <PriceText>{kidsBelt}</PriceText>
        </PriceRow>
        <OpaqueLine />
        <PriceRow>
          <PriceTitleText>ADULT GI</PriceTitleText>
          <PriceText>{adultGi}</PriceText>
        </PriceRow>
        <OpaqueLine />
        <PriceRow>
          <PriceTitleText>ADULT RASHGUARD</PriceTitleText>
          <PriceText>{adultRashguard}</PriceText>
        </PriceRow>
        <OpaqueLine />
        <PriceRow>
          <PriceTitleText>ADULT SHORTS</PriceTitleText>
          <PriceText>{adultShorts}</PriceText>
        </PriceRow>
        <OpaqueLine />
        <PriceRow>
          <PriceTitleText>ADULT BELT</PriceTitleText>
          <PriceText>{adultBelt}</PriceText>
        </PriceRow>
        <OpaqueLine />
      </PriceContainer>
    </Root>
  )
}

export default GearPriceInfo
