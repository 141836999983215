import React from 'react'
import Helmet from 'react-helmet'
import styled from 'styled-components'

const LeadForm = styled.div`
  background-color: ${(props) => props.whiteBackground ? 'white' : ''};

`

const LeadGenForm = ({ block, block: { whiteBackground }}) => {
  return (
  <LeadForm whiteBackground={whiteBackground} id="leadcapture_419f8743-e1e1-4a35-89c0-26d3c82d653d">
    <Helmet>
      <script type="text/javascript">{`(function(win, doc, zenJSHost, src, module, partitionApiKey, widgetInstanceId){
        win.zenplanner = win.zenplanner || {};
        win.zenplanner.directLoadArgs = win.zenplanner.directLoadArgs || [];
        var tryCount = 0, intervalId = null;
        function afterLoad(){
            if(win.zenplanner.directLoader !== undefined && window.zenplanner.directLoader !== null){
                clearInterval(intervalId);
                for(var i = 0, l = win.zenplanner.directLoadArgs.length; l > i; i++){
                    if(win.zenplanner.directLoadArgs[i].widgetInstanceId === widgetInstanceId) {
                        win.zenplanner.directLoader.loadWidget(zenJSHost, module, partitionApiKey, widgetInstanceId);
                    }
                }
            } else if(tryCount++ > 200) {
                console.log('Zen Planner widget : ' + module + ', failed to load.');
                clearInterval(intervalId);
            }
        }
        if(win.zenplanner.directLoader === undefined || win.zenplanner.directLoader === null){
            win.zenplanner.directLoadArgs.push({
                module: module, partitionApiKey: partitionApiKey, widgetInstanceId : widgetInstanceId
            });
            var s = doc.createElement('script'); s.async = 1; s.src = zenJSHost + '/' + src;
            doc.head.appendChild(s);
            intervalId = setInterval(afterLoad, 50);
        } else {
            win.zenplanner.directLoader.loadWidget(zenJSHost, module, partitionApiKey, widgetInstanceId);
        }
      })(window, document, 'https://studio.zenplanner.com', 'zenplanner/studio/target/zp-widget-direct.js', 'leadcapture', 'dd31bc9e-df4e-4c46-b7e9-97cbcf08470b', '419f8743-e1e1-4a35-89c0-26d3c82d653d');`}</script>
    </Helmet>
  </LeadForm>
  )
}

export default LeadGenForm;