import React from 'react'
import styled from 'styled-components'
import Swiper from 'react-id-swiper'
import { below } from '../styles/abstracts/breakpoints.js'

const OuterContainer = styled.div`
  display: flex;
  display: -webkit-flex;
  display: -ms-flexbox;
  flex-wrap: wrap;
  overflow: auto;
  background-color: ${props => props.theme.color.grey.light};
  font-family: ${props => props.theme.font.family.base};

  ${below.md} {
    width: 100%;
  }
`

const TrainChampionsColumn1 = styled.div`
  display: block;
  width: 100%;
  flex: 1;
  padding-top: 116px;
  padding-left: 135px;
  padding-right: 135px;
  padding-bottom: 116px;

  ${below.md} {
    min-width: calc(100% - 40px);
    padding-left: 20px;
    padding-top: 83px;
    padding-right: 20px;
    padding-bottom: 35px;
  }
`

const TrainChampionsColumn2 = styled.div`
  display: block;
  width: 100%;
  padding: 6em;
  flex: 1;
  padding-left: 0px;
  padding-right: 135px;
  overflow: hidden;

  ${below.md} {
    padding-top: 0em;
    padding-left: 20px;
    padding-right: 20px;
  }
`

const SwiperWrapper = styled.div`
  width: 100%;
  flex-wrap: wrap;
  padding-top: 1em;
  padding-bottom: 1em;

  .swiper-container {
    width: 100%;
    height: auto;
  }
  .swiper-slide {
    width: 100%;
  }

  .swiper-pagination-bullet {
    background: ${props => props.theme.color.grey.dark};
  }

  .swiper-pagination-bullet-active {
    background: ${props => props.theme.color.yellow};
  }
`

const HeaderText = styled.h2`
  font-size: 38px;
  font-weight: bold;
  margin-top: 0px;
  margin-bottom: 0px;
  color: ${props => props.theme.font.color.grey.dark};
`
const Text = styled.div`
  flex-wrap: wrap;
  font-size: ${props => props.theme.font.size.base};
  font-weight: 300;
  line-height: 2em;
  color: ${props => props.theme.font.color.grey.medium};
  padding-top: 1em;
  padding-bottom: 1em;
  ${below.md} {
    font-size: 18px;
    line-height: 30px;
  }
`

const ImageWrapper = styled.div`
  margin-top: auto;
  margin-bottom: auto;
  img {
    margin-top: auto;
    margin-bottom: auto;
    display: block;
    width: 100%;
  }
`

const TrainChampions = ({ block, block: { header, body, tournaments } }) => {
  const params = {
    spaceBetween: 20,
    autoplay: {
      delay: 5000,
      speed: 900
    },
    slidesPerView: 4,
    loop: true,
    autoHeight: true
  }
  return (
    <OuterContainer>
      <TrainChampionsColumn1>
        <HeaderText>{header}</HeaderText>
        <Text>{body}</Text>
      </TrainChampionsColumn1>
      <TrainChampionsColumn2>
        <SwiperWrapper>
          <Swiper {...params}>
            {tournaments.map((tournament, i) => (
              <ImageWrapper key={i}>
                <img
                  src={tournament.image.asset.url}
                  alt={tournament.name}
                  title={tournament.name}
                />
              </ImageWrapper>
            ))}
          </Swiper>
        </SwiperWrapper>
      </TrainChampionsColumn2>
    </OuterContainer>
  )
}

export default TrainChampions
