import React, { useRef } from 'react'
import styled from 'styled-components'
import { below } from '../styles/abstracts/breakpoints.js'
import Swiper from 'react-id-swiper'
import 'swiper/css/swiper.css'
import { FluidImg } from '../elements'
import Polygon from '../components/polygon'

const Wrapper = styled.div`
  background-color: ${props => props.backgroundColor.hex};
  position: relative;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  z-index: 0;
  overflow-y: hidden;

  ${below.md} {
    padding-bottom: 55px;
  }
`

const PolygonWrapper = styled.div`
  z-index: -1;
  position: absolute;
  float: left;
  display: block;
  width: 100%;
  height: 100%;
`

const OuterContainer = styled.div`
  z-index: 1;
  width: 100%;
`

const ImageWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 50px;

  ${below.md} {
    padding: 33px;
  }
`

const Root = styled.div`
  width: 100%;
  font-family: Source Sans Pro;
  margin-bottom: -5px;
  overflow: hidden;
  display: inline-block;
`

const SwiperWrapper = styled.div`
  z-index: -2;

  .swiper-container {
  }

  .swiper-pagination-bullet {
    background: ${props => props.theme.color.grey.dark};
  }

  .swiper-pagination-bullet-active {
    background: ${props => props.theme.color.yellow};
  }

  .swiper-button-next {
    color: ${props => props.theme.color.grey.dark};
  }

  .swiper-button-prev {
    color: ${props => props.theme.color.grey.dark};
  }
`

const ImageCarousel = ({ block, block: { carouselImages, backgroundColor } }) => {
  const itemsRef = useRef([])

  const params = {
    autoplay: {
      delay: 2500
    },
    pagination: {
      el: '.swiper-pagination'
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    },
    spaceBetween: 50,
    breakpoints: {
      0: {
        slidesPerView: 1,
        spaceBetween: 20
      },
      785: {
        slidesPerView: 2,
        spaceBetween: 30
      },
      1200: {
        slidesPerView: 3,
        spaceBetween: 50
      },
      1800: {
        slidesPerView: 4,
        spaceBetween: 50
      }
    }
  }

  const images = carouselImages.slides

  return (
    <Wrapper backgroundColor={backgroundColor}>
      <PolygonWrapper>
        <Polygon />
      </PolygonWrapper>
      <OuterContainer>
        <ImageWrapper>
          <Root>
            <SwiperWrapper>
              <Swiper {...params}>
                {images.map((image, i) => (
                  <div
                    key={image.asset._id}
                    ref={el => (itemsRef.current[i] = el)}
                    name={image.name}
                  >
                    <FluidImg id={image.asset._id} width={260} alt={image.name} />
                  </div>
                ))}
              </Swiper>
            </SwiperWrapper>
          </Root>
        </ImageWrapper>
      </OuterContainer>
    </Wrapper>
  )
}

export default ImageCarousel
