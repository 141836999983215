import React, { Component } from 'react'
import { breakpointIntegers } from '../styles/abstracts/breakpoints.js'

const canvasCoordinatesInitial = {
  pointOneX: 0,
  pointOneY: 80,
  pointTwoX: 820,
  pointTwoY: 0,
  pointThreeX: 1180,
  pointThreeY: 540,
  pointFourX: 0,
  pointFourY: 485
}

const {
  pointOneX,
  pointOneY,
  pointTwoX,
  pointTwoY,
  pointThreeX,
  pointThreeY,
  pointFourX,
  pointFourY
} = canvasCoordinatesInitial

class Polygon extends Component {
  constructor(props) {
    super(props)
    this.state = {
      pointOneX,
      pointOneY,
      pointTwoX,
      pointTwoY,
      pointThreeX,
      pointThreeY,
      pointFourX,
      pointFourY
    }
  }

  componentDidMount() {
    this.updateCanvas()
  }

  componentWillMount() {
    if (typeof window !== `undefined`) {
      window.addEventListener('resize', this.handleResize)
    }
  }

  componentWillUnmount() {
    if (typeof window !== `undefined`) {
      window.removeEventListener('resize', this.handleResize)
    }
  }

  handleResize = () => {
    this.resizePolygon(this.getResizeFactor())
  }

  getResizeFactor = () => {
    if (window.innerWidth < 704) {
      return 2
    }
    if (window.innerWidth > breakpointIntegers.xl && window.innerWidth < breakpointIntegers.xxxl) {
      return 1
    }
    if (window.innerWidth > breakpointIntegers.xxxl) {
      return 1.35
    } else {
      // the breakpoint for the base shape is 1400px. Other breakpoints trigger custom resize
      return window.innerWidth / 1400
    }
  }

  resizePolygon = resizeFactor => {
    this.setState({ pointOneY: pointOneY * resizeFactor })
    this.setState({ pointTwoX: pointTwoX * resizeFactor })
    this.setState({ pointThreeX: pointThreeX * resizeFactor })
    this.setState({ pointThreeY: pointThreeY * resizeFactor })
    this.setState({ pointFourY: pointFourY * resizeFactor })
    this.updateCanvas()
  }

  updateCanvas = () => {
    const {
      pointOneX,
      pointOneY,
      pointTwoX,
      pointTwoY,
      pointThreeX,
      pointThreeY,
      pointFourX,
      pointFourY
    } = this.state

    const ctx = this.refs.canvas.getContext('2d')
    ctx.beginPath()
    ctx.moveTo(pointOneX, pointOneY)
    ctx.lineTo(pointTwoX, pointTwoY)
    ctx.lineTo(pointThreeX, pointThreeY)
    ctx.lineTo(pointFourX, pointFourY)
    ctx.fillStyle = '#FFBD00'
    ctx.fill()
  }

  render() {
    return <canvas ref="canvas" width={this.state.pointThreeX} height={this.state.pointThreeY} />
  }
}

export default Polygon
