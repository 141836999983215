import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import logo from '../images/allianceLogoBig.png'
import partialLogoHighRes from '../images/alliance-bjj-logo.gif'

const OuterContainer = styled.div`
  background-color: #272727;
  font-family: Source Sans Pro;
  background-image: url(${partialLogoHighRes});
  background-repeat: no-repeat;
  background-position: left bottom;
  background-size: 342px 390px;

  @media (min-width: 901px) and (max-width: 1270px) {
    background-size: 308px 351px;
  }
  @media (max-width: 900px) {
    background-image: none;
  }
`
const InnerTop = styled.div``
const InnerBottom = styled.div`
  overflow: hidden;
`
const BottomContent = styled.div`
  display: flex;
  width: 70%;
  margin-left: 20%;

  @media (min-width: 1446px) and (max-width: 1685px) {
    margin-left: 25%;
  }
  @media (min-width: 1271px) and (max-width: 1445px) {
    margin-left: 30%;
  }
  @media (min-width: 901px) and (max-width: 1270px) {
    margin-left: 35%;
  }
  @media (max-width: 900px) {
    flex-direction: column;
    margin-left: 0px;
    padding-right: 20px;
    width: 100%;
  }
`
const Column = styled.div`
  width: 50%;
  color: white;
  padding: 15px;
  display: block;
  flex-direction: column;
  justify-content: center;

  @media (max-width: 900px) {
    width: 90%;
    padding-left: 20px;
    padding-right: 20px;
  }
`
const Column2 = styled.div`
  width: 50%;
  color: white;
  padding: 15px;
  margin-left: 100px;
  margin-bottom: 40px;
  display: block;
  flex-direction: column;

  @media (min-width: 1446px) and (max-width: 1685px) {
    margin-left: 50px;
  }
  @media (min-width: 1271px) and (max-width: 1445px) {
    margin-left: 10px;
  }
  @media (min-width: 901px) and (max-width: 1270px) {
    margin-left: 0px;
    width: 30%;
  }
  @media (max-width: 900px) {
    width: 98%;
    margin-left: 0px;
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 0px;
  }
`
const LearnMoreButton = styled.div`
  background-color: #ffbc00;
  width: 160px;
  min-height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: rgba(255, 187, 0, 0.89);
    transition: 0.5s;
  }
`

const ChampionBlock = styled.div`
  padding-bottom: 10px;
`
const ChampionBlockBottom = styled.div`
  border-bottom: none;
`
const DividerLine = styled.div`
  opacity: 0.3;
  border: 0.5px solid #979797;
  width: 90%;

  @media (max-width: 900px) {
    width: 90%;
  }
`
const MobileLogo = styled.div`
  display: none;
  margin: auto;

  @media (max-width: 900px) {
    display: block;
  }
`
const HeaderText = styled.h2`
  font-size: 38px;
  font-weight: bold;
  margin-top: 0px;
  margin-bottom: 0px;
`
const GreyText = styled.div`
  color: #dddddd;
  font-size: 18px;
  line-height: 30px;
  letter-spacing: -0.24px;
  font-weight: 300;
`
const LargeYellowText = styled.div`
  font-size: 58px;
  font-weight: bold;
  color: #ffbd00;
  letter-spacing: -0.77px;
  margin-top: 10px;
`
const BoldWhiteText = styled.div`
  font-weight: bold;
  font-size: 18px;
  margin-top: -5px;
  margin-bottom: 15px;
`
const ButtonText = styled.div`
  font-weight: 600;
  color: black;
  font-size: 15px;
`

const StyledLink = styled(Link)`
  text-decoration: none;
`

const AllianceSummary = ({
  block,
  block: { header, body, worldChampions, euroChampions, brazilChampions }
}) => (
  <OuterContainer>
    <InnerTop />
    <InnerBottom>
      <BottomContent>
        <Column>
          <HeaderText>{header}</HeaderText>
          <br />
          <GreyText>{body}</GreyText>
          <br />
          <LearnMoreButton>
            <StyledLink to="/about">
              <ButtonText>LEARN MORE</ButtonText>
            </StyledLink>
          </LearnMoreButton>
        </Column>
        <Column2>
          <ChampionBlock>
            <LargeYellowText>{worldChampions}X</LargeYellowText>
            <BoldWhiteText>WORLD CHAMPIONS</BoldWhiteText>
          </ChampionBlock>
          <DividerLine />
          <ChampionBlock>
            <LargeYellowText>{euroChampions}X</LargeYellowText>
            <BoldWhiteText>EUROPEAN CHAMPIONS</BoldWhiteText>
          </ChampionBlock>
          <DividerLine />
          <ChampionBlockBottom>
            <LargeYellowText>{brazilChampions}X</LargeYellowText>
            <BoldWhiteText>BRAZIL NATIONAL CHAMPIONS</BoldWhiteText>
          </ChampionBlockBottom>
        </Column2>
        <MobileLogo>
          <img src={logo} />
        </MobileLogo>
      </BottomContent>
    </InnerBottom>
  </OuterContainer>
)

export default AllianceSummary
