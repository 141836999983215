import React, { useState, useEffect } from 'react'
import ReactPlayer from 'react-player'
import styled from 'styled-components'

const PlayerWrapper = styled.div`
  position: relative;
  padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
`

const StyledReactPlayer = styled(ReactPlayer)`
  position: absolute;
  top: 0;
  left: 0;
`

const VideoPlayer = ({ block, block: { url } }) => {
  const [hasLoaded, setHasLoaded] = useState(false)
  const [width, setWidth] = useState(typeof window !== 'undefined' ? window.innerWidth : 1920)

  useEffect(() => {
    if (!hasLoaded) {
      setWidth(window.innerWidth)
      setHasLoaded(true)
    }
  })
  const mobilePlayer = (
    <StyledReactPlayer
      config={{
        youtube: {
          preload: true
        },
        vimeo: {
          preload: true
        }
      }}
      url={url}
      playing
      loop
      controls
      autoPlay
      volume={null}
      width="100%"
      height="100%"
    />
  )
  const desktopPlayer = (
    <StyledReactPlayer
      config={{
        youtube: {
          preload: true
        },
        vimeo: {
          preload: true
        }
      }}
      url={url}
      playing
      loop
      controls
      autoPlay
      volume={null}
      muted
      width="100%"
      height="100%"
    />
  )
  return hasLoaded ? (
    <PlayerWrapper>{width <= 1200 ? mobilePlayer : desktopPlayer}</PlayerWrapper>
  ) : (
    <PlayerWrapper />
  )
}

export default VideoPlayer
