import React from 'react'
import styled from 'styled-components'
import Header from './header'
import { below } from '../styles/abstracts/breakpoints.js'

const Root = styled.div`
  overflow: auto;
  font-family: Source Sans Pro;
`

const InnerContainer = styled.div`
  margin-top: 75px;

  height: ${props => {
    if (props.subHeaderText !== undefined) {
      return '345px'
    } else {
      return '300px'
    }
  }};

  background-color: ${props => props.theme.color.grey.dark};
  display: flex;
  align-items: center;

  ${below.md} {
    margin-top: 68px;
  }
`
const TextContainer = styled.div`
  margin-left: 50px;
  display: flex;
  flex-direction: column;

  @media (max-width: 785px) {
    width: 90%;
    margin-left: 20px;
  }
`
const HeaderText1 = styled.h1`
  font-size: 58px;
  color: ${props => props.theme.color.white};
  font-weight: bold;
  letter-spacing: -0.77px;
  margin-top: 0px;
  margin-bottom: 0px;
  @media (max-width: 785px) {
    font-size: 35px;
  }
`
const HeaderText2 = styled.h1`
  font-size: 58px;
  color: ${props => props.theme.color.black};
  font-weight: bold;
  background-color: ${props => props.theme.color.yellow};
  letter-spacing: -0.77px;
  padding-left: 0.25em;
  padding-right: 0.25em;
  margin-top: 0px;
  margin-bottom: 0px;
  @media (max-width: 785px) {
    font-size: 35px;
  }
`
const SubHeaderText = styled.div`
  font-weight: 300;
  font-size: 21px;
  margin-top: 18px;
  color: ${props => props.theme.color.white};
`

const TitleBlock = ({ block: { title, subTitle, subHeader, description } }) => {
  return (
    <Root>
      <InnerContainer subHeaderText={title}>
        <TextContainer>
          <HeaderText1>{title}</HeaderText1>
          <HeaderText2>{subTitle}</HeaderText2>
          <SubHeaderText>{subHeader}</SubHeaderText>
        </TextContainer>
      </InnerContainer>
    </Root>
  )
}
export default TitleBlock
