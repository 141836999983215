import React from 'react'
import styled from 'styled-components'
import { below } from '../styles/abstracts/breakpoints.js'
import Polygon from '../components/polygon'
import theme from '../styles/abstracts/theme'

const Wrapper = styled.div`
  background-color: ${props => {
    if (props.greyWrapperBackground === true) {
      return theme.color.grey.dark
    }
  }};
  position: relative;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  z-index: 0;
  overflow-y: hidden;
  padding-bottom: ${props => {
    if (props.paddingBottom === true) {
      return '0'
    } else {
      return '3em;'
    }
  }};
  top: ${props => {
    if (props.distanceFromTop === 4) {
      return '-18em'
    } else if (props.distanceFromTop === 3) {
      return '-12em'
    } else if (props.distanceFromTop === 2) {
      return '-7em'
    } else if (props.distanceFromTop === 1) {
      return '0em'
    } else {
      return '-18em'
    }
  }};
  margin: 0 auto
    ${props => {
    if (props.distanceFromTop === 4) {
      return '-18em'
    } else if (props.distanceFromTop === 3) {
      return '-12em'
    } else if (props.distanceFromTop === 2) {
      return '-7em'
    } else if (props.distanceFromTop === 1) {
      return '0em'
    } else {
      return '-18em'
    }
  }}
    auto;
  ${below.md} {
    padding-bottom: 55px;
  }
`

const PolygonWrapper = styled.div`
  z-index: -1;
  position: absolute;
  float: left;
  display: block;
  width: 100%;
  height: 100%;
`

const BackgroundBlockGrey = styled.div`
  top: ${props => props.greyBackgroundCoverFromTop}%;
  z-index: -2;
  position: absolute;
  display: block;
  height: 100%;
  width: 100%;
`

const BackgroundBlockLightGrey = styled(BackgroundBlockGrey)`
  top: ${props => props.lightGreyBackgroundCoverFromTop}%;
`

const PolygonContainerContentBlock = props => {
  return (
    <Wrapper distanceFromTop={props.distanceFromTop}>
      {props.childComponent}
      <PolygonWrapper>
        <Polygon />
        <BackgroundBlockGrey
          greyBackgroundCoverFromTop={props.greyBackgroundCoverFromTop}
          style={{ backgroundColor: theme.color.grey.dark }}
        />
        <BackgroundBlockLightGrey
          lightGreyBackgroundCoverFromTop={props.lightGreyBackgroundCoverFromTop}
          style={{ backgroundColor: theme.color.grey.light }}
        />
      </PolygonWrapper>
    </Wrapper>
  )
}

export default PolygonContainerContentBlock
