import React from 'react'
import Helmet from 'react-helmet'
import styled from 'styled-components'

const KidsFirstClass = styled.div`
  background-color: ${(props) => props.whiteBackground ? 'white' : ''};

`

const ZenKidsFirstClass = ({ block, block: { whiteBackground }}) => {
  return (
  <KidsFirstClass whiteBackground={whiteBackground} id="freetrial_062eb399-8964-4b36-a5ad-c7d856c563a4">
    <Helmet>
    <script type="text/javascript">{`(function(win, doc, zenJSHost, src, module, partitionApiKey, widgetInstanceId){ win.zenplanner = win.zenplanner || {}; win.zenplanner.directLoadArgs = win.zenplanner.directLoadArgs || []; var tryCount = 0, intervalId = null; function afterLoad(){ if(win.zenplanner.directLoader !== undefined && window.zenplanner.directLoader !== null){ clearInterval(intervalId); for(var i = 0, l = win.zenplanner.directLoadArgs.length; l > i; i++){ if(win.zenplanner.directLoadArgs[i].widgetInstanceId === widgetInstanceId) { win.zenplanner.directLoader.loadWidget(zenJSHost, module, partitionApiKey, widgetInstanceId); } } } else if(tryCount++ > 200) { console.log('Zen Planner widget : ' + module + ', failed to load.'); clearInterval(intervalId); } } if(win.zenplanner.directLoader === undefined || win.zenplanner.directLoader === null){ win.zenplanner.directLoadArgs.push({ module: module, partitionApiKey: partitionApiKey, widgetInstanceId : widgetInstanceId }); var s = doc.createElement('script'); s.async = 1; s.src = zenJSHost + '/' + src; doc.head.appendChild(s); intervalId = setInterval(afterLoad, 50); } else { win.zenplanner.directLoader.loadWidget(zenJSHost, module, partitionApiKey, widgetInstanceId); } })(window, document, 'https://studio.zenplanner.com', 'zenplanner/studio/target/zp-widget-direct.js', 'freetrial', 'dd31bc9e-df4e-4c46-b7e9-97cbcf08470b', '062eb399-8964-4b36-a5ad-c7d856c563a4');`}</script>
    </Helmet>
  </KidsFirstClass>
  )
}

export default ZenKidsFirstClass