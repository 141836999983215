import React, { useRef } from 'react'
import styled from 'styled-components'
import Swiper from 'react-id-swiper'
import 'swiper/css/swiper.css'
import { below } from '../styles/abstracts/breakpoints.js'

const StyledDiv = styled.div`
  background: ${props => props.backgroundColor};

  ${below.md} {
    padding-top: 50px;
  }
  ${below.sm} {
    padding-top: ${props => props.topPaddingMobile}px;
  }
  @media (max-width: 500px) {
    padding-top: ${props => props.topPaddingMobile + 25}px;
  }
  @media (max-width: 400px) {
    padding-top: ${props => props.topPaddingMobile + 50}px;
  }
  @media (max-width: 350px) {
    padding-top: ${props => props.topPaddingMobile + 75}px;
  }
`
const ImageDiv = styled.div`
  width: 100%;
  height: 980px;
  background-image: url(${props => props.imageUrl});
  background-size: cover;
  background-position: top center;
  ${below.md} {
    height: 525px;
  }
  ${below.sm} {
    height: 325px;
  }
`

const SimpleSwiper = props => {
  const { images, backgroundColor, topPaddingMobile } = props

  const params = {
    spaceBetween: 0,
    centeredSlides: true,
    autoplay: {
      delay: 4000,
      speed: 900
    },
    pagination: {
      el: '.swiper-pagination',
      clickable: true
    }
  }

  const itemsRef = useRef([])

  return (
    <Swiper {...params}>
      {images.map((image, i) => (
        <StyledDiv
          topPaddingMobile={topPaddingMobile || null}
          backgroundColor={backgroundColor ? backgroundColor.hex : null}
          key={image.asset._id}
          ref={el => (itemsRef.current[i] = el)}
          name={image.name}
        >
          <ImageDiv imageUrl={image.asset.url} />
        </StyledDiv>
      ))}
    </Swiper>
  )
}

export default SimpleSwiper
