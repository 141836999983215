import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { below } from '../styles/abstracts/breakpoints.js'
import BlockContent from '../components/block-content'
import ReactPlayer from 'react-player'

const OuterContainer = styled.div`
  background-color: ${props => props.theme.color.white};
  display: flex;
  display: -webkit-flex;
  display: -ms-flexbox;
  flex-wrap: wrap;
  overflow: visible;

  ${below.md} {
    width: 100%;
    overflow: hidden;
  }
`

const ContentWrapper = styled.div`
  flex-wrap: wrap;
  color: ${props => props.theme.font.color.grey.medium};
  font-family: ${props => props.theme.font.family.base};
  font-size: 21px;
  font-weight: 300;

  padding: 4.75em;
  padding-left: 6.25em;
  padding-right: 6.25em;

  ${below.md} {
    padding-left: 1.25em;
    padding-right: 1.25em;
    font-size: 18px;
    line-height: 30px;
  }
`

const PlayerContainer = styled.div`
  width: 45%;
  padding-right: 5%;
  display: inline-block;

  ${below.lg} {
    width: 100%;
  }
`

const PlayerWrapper = styled.div`
  position: relative;
  padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
`

const StyledReactPlayer = styled(ReactPlayer)`
  position: absolute;
  top: 0;
  left: 0;
`

const TextWrapper = styled.div`
  width: 50%;
  display: inline-block;
  float: right;

  ${below.lg} {
    width: 100%;
    padding-top: 95px;
  }
`

const TitlehWrapper = styled.div`
  margin-bottom: 25px;
  margin-top: 0px;
  h2 {
    margin-top: 0px;
  }
`

const Title = styled(BlockContent)`
  letter-spacing: -0.51px;
`

const ParagraphWrapper = styled.div`
  letter-spacing: -0.24px;
`

const Paragraph = styled(BlockContent)``

const VideoPlayerTextBlock = ({ block, block: { title, text, url } }) => {
  const [hasLoaded, setHasLoaded] = useState(false)
  const [width, setWidth] = useState(typeof window !== 'undefined' ? window.innerWidth : 1920)

  useEffect(() => {
    if (!hasLoaded) {
      setWidth(window.innerWidth)
      setHasLoaded(true)
    }
  })
  const mobilePlayer = (
    <StyledReactPlayer
      config={{
        youtube: {
          preload: true
        },
        vimeo: {
          preload: true
        }
      }}
      url={url}
      playing
      loop
      controls
      autoplay
      volume={null}
      width={'100%'}
      height={'100%'}
    />
  )
  const desktopPlayer = (
    <StyledReactPlayer
      config={{
        youtube: {
          preload: true
        },
        vimeo: {
          preload: true
        }
      }}
      url={url}
      playing
      loop
      controls
      autoplay
      muted
      volume={null}
      width={'100%'}
      height={'100%'}
    />
  )
  return hasLoaded ? (
    <OuterContainer>
      <ContentWrapper>
        <PlayerContainer>
          <PlayerWrapper>{width <= 1200 ? mobilePlayer : desktopPlayer}</PlayerWrapper>
        </PlayerContainer>
        <TextWrapper>
          <TitlehWrapper>
            <Title blocks={title || []} />
          </TitlehWrapper>
          <ParagraphWrapper>
            <Paragraph blocks={text || []} />
          </ParagraphWrapper>
        </TextWrapper>
      </ContentWrapper>
    </OuterContainer>
  ) : (
    <OuterContainer />
  )
}
export default VideoPlayerTextBlock
