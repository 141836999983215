import { Link } from 'gatsby'
import React from 'react'
import styled from 'styled-components'
import { above, below } from '../styles/abstracts/breakpoints'
import { FaStar } from 'react-icons/fa'

const OuterBanner = styled.div`
  background-color: #272727;
  height: 275px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Source Sans Pro;
  position: relative;

  a:hover,
  a:visited,
  a:link,
  a:active {
    text-decoration: none;
  }

  @media (max-width: 785px) {
    align-items: left;
    justify-content: left;
    height: 281px;
  }
`

const OuterBanner2 = styled(OuterBanner)`
  height: 160px;
`

const InnerContainer = styled.div`
  display: flex;

  ${above.md} {
    align-items: center;
  }

  @media (max-width: 785px) {
    flex-direction: column;
  }
`
const Button = styled.div`
  margin-left: 50px;
  margin-top: 45px;
  background-color: #ffbc00;
  width: 198px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: rgba(255, 187, 0, 0.89);
    transition: 0.3s;
  }
  ${below.md} {
    margin-left: 20px;
    margin-top: 20px;
  }
`

const Button2 = styled(Button)`
  margin-top: 0px;
`

const ButtonText = styled.div`
  font-weight: 600;
  font-size: 15px;
  text-decoration: none;
  color: black;
`
const InnerText = styled.div`
  display: flex;
  width: 455px;
  flex-direction: column;
  @media (max-width: 785px) {
    margin-left: 20px;
    margin-bottom: 0px;
    width: 90%;
  }
`

const InnerText2 = styled(InnerText)`
  width: auto;
`

const HeaderText = styled.h2`
  font-size: 38px;
  color: white;
  font-weight: bold;
  margin-top: 0px;
  margin-bottom: 24px;
  ${below.md} {
    margin-bottom: 20px;
  }
`

const HeaderText2 = styled(HeaderText)`
  margin-bottom: 0px;
`

const SubheaderText = styled.div`
  color: ${props => props.theme.font.color.grey.light};
  font-size: 18px;
  letter-spacing: -0.24px;
  line-height: 30px;
  font-weight: 300;
`
const RedStar = styled(FaStar)`
  font-size: 14px;
  color: #d22828;
  margin-right: 2px;
`
const RedText = styled.div`
  color: #d22828;
  font-size: 16px;
`
const CallToAction = ({
  block,
  block: { cTAHeader, cTASubHeader, buttonText, path, showNewClassMessage }
}) =>
  cTASubHeader ? (
    <OuterBanner>
      <InnerContainer>
        <InnerText>
          <HeaderText>{cTAHeader}</HeaderText>
          <SubheaderText>
            {cTASubHeader}
            {showNewClassMessage ? (
              <RedText>
                <RedStar />
                11 more classes available per week!
              </RedText>
            ) : null}
          </SubheaderText>
        </InnerText>
        <Link to={`${path}`}>
          <Button>
            <ButtonText>{buttonText}</ButtonText>
          </Button>
        </Link>
      </InnerContainer>
    </OuterBanner>
  ) : (
    <OuterBanner2>
      <InnerContainer>
        <InnerText2>
          <HeaderText2>{cTAHeader}</HeaderText2>
        </InnerText2>
        <Link to={`${path}`}>
          <Button2>
            <ButtonText>{buttonText}</ButtonText>
          </Button2>
        </Link>
      </InnerContainer>
    </OuterBanner2>
  )

export default CallToAction
