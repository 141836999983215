import React from 'react'
import styled, { keyframes } from 'styled-components'
import { FaPlusSquare, FaMinusSquare } from 'react-icons/fa'
import { below, breakpointIntegers } from '../styles/abstracts/breakpoints.js'
import BlockContent from '../components/block-content'

const Root = styled.div`
  background-color: ${props => props.theme.color.white};
  height: auto;
  padding-top: 75px;
  padding-bottom: 75px;
  display: flex;
  align-content: center;
  justify-content: center;
  font-family: Source Sans Pro;

  ${below.lg} {
    padding-top: 65px;
  }
  ${below.md} {
    flex-direction: column;
    height: auto;
    padding-bottom: 45px;
    padding-top: 20px;
  }
`
const DescriptionContainer = styled.div`
  width: 30%;
  margin-right: 100px;

  ${below.md} {
    width: 90%;
    margin-right: 0px;
    margin-left: 20px;
    margin-bottom: 30px;
    font-size: 18px;
    line-height: 30px;
  }
`

const ClassesContainer = styled.div`
  width: 45%;
  display: flex;
  flex-direction: column;
  margin-top: -15px;
  ${below.md} {
    width: 90%;
    margin-left: 20px;
    margin-top: 0px;
  }
`
const HeaderText = styled.div`
  color: #333333;
  font-weight: bold;
  font-size: 38px;
  letter-spacing: -0.51px;
  line-height: 47px;
  margin-bottom: 30px;
`
const Text = styled.div`
  color: #474747;
  font-weight: 300;
  font-size: 18px;
  ${below.md} {
    font-size: 18px;
    line-height: 30px;
  }
`
const TitleText = styled.div`
  font-weight: bold;
  color: #222222;
  letter-spacing: -0.24px;
  float: left;
  font-size: 18px;
  ${below.md} {
    margin-bottom: 12px;
  }
`
const ClassTimeText = styled(Text)`
  display: flex;
  font-weight: 500;
  float: right;
`
const Row = styled.div`
  padding-top: 21px;
  padding-bottom: 21px;
`

const OpaqueLine = styled.div`
  opacity: 0.3;
  border: 0.5px solid #979797;
`

const fadeIn = keyframes`
0% {
  opacity: 0;
}
100% {
  opacity: 1;
}
`

const DescriptionText = styled(Text)`
  font-weight: 500;
  float: right;
  padding-top: 20px;
  animation-name: ${fadeIn};
  animation-timing-function: ease-in;
  animation-duration: 0.4s;
`

const ExpandIcon = styled(FaPlusSquare)`
  float: right;
  margin-top: 3px;
  margin-left: 10px;
`
const ContractIcon = styled(FaMinusSquare)`
  float: right;
  margin-top: 3px;
  margin-left: 10px;
`

class Accordion extends React.Component {
  constructor(props) {
    super(props)

    //Setup initial toggle status of each question in the FAQ
    let initialState = {}
    this.props.block.classes.forEach((kidsClass, i) => {
      initialState[i] = false
    })

    this.state = initialState
  }

  toggleExpansion = i => {
    this.setState({
      [i]: !this.state[i]
    })
  }

  render() {
    const kidsClassList = this.props.block.classes.map((kidsClassObj, i) => {
      let toggleIcon = (
        <ExpandIcon
          onClick={() => {
            this.toggleExpansion(i)
          }}
        />
      )

      let description = ''
      if (this.state[i]) {
        toggleIcon = (
          <ContractIcon
            onClick={() => {
              this.toggleExpansion(i)
            }}
          />
        )
        description = <DescriptionText>{kidsClassObj.description}</DescriptionText>
      }

      return (
        <>
          <Row
            key={i}
            onClick={() => {
              this.toggleExpansion(i)
            }}
          >
            <TitleText>{kidsClassObj.class}</TitleText>
            <ClassTimeText>
              {kidsClassObj.classTime}
              {toggleIcon}
            </ClassTimeText>
            {description}
          </Row>
          <OpaqueLine />
        </>
      )
    })
    return (
      <Root>
        <DescriptionContainer>
          <HeaderText>{this.props.block.header}</HeaderText>
          <Text>{this.props.block.body || []}</Text>
        </DescriptionContainer>
        <ClassesContainer>{kidsClassList}</ClassesContainer>
      </Root>
    )
  }
}

export default Accordion
