import React from 'react'
import styled from 'styled-components'
import { above, below } from '../styles/abstracts/breakpoints.js'
import BlockContent from '../components/block-content'

const OuterContainer = styled.div`
  background-color: ${props => props.theme.color.white};
  display: flex;
  display: -webkit-flex;
  display: -ms-flexbox;
  flex-wrap: wrap;
  overflow: visible;

  ${below.md} {
    width: 100%;
    overflow: hidden;
  }

  ${below.xxl} {
    flex-direction: column-reverse;
  }
`

const ContentWrapper = styled.div`
  flex-wrap: wrap;
  color: ${props => props.theme.font.color.grey.medium};
  font-family: ${props => props.theme.font.family.base};
  font-size: 21px;
  font-weight: 300;

  padding: 3.125em;
  padding-left: 6.25em;
  padding-right: 6.25em;
  ${props => (props.reducePadding ? 'padding-top: 0.5em' : '')};
  ${below.md} {
    padding-left: 1.25em;
    padding-right: 1.25em;
  }
`

const TitlehWrapper = styled.div`
  margin-bottom: 25px;
`

const Title = styled(BlockContent)`
  letter-spacing: -0.51px;
`

const ParagraphWrapper = styled.div`
  column-count: 2;
  column-gap: 50px;
  letter-spacing: -0.24px;
  h2 {
    margin: 0px;
  }
  p {
    margin-top: 0px;
  }
  img {
    max-width: 200px;
    ${below.md} {
      width: 100%;
      max-width: none;
    }
  }
  figure {
    float: left;
    margin-bottom: auto;
    margin-right: 50px;
  }
  ${above.md} {
    figure:nth-child(1) {
      margin-top: 6px;
    }
    ,
    figure:nth-child(n + 2) {
      /* margin-top: 42.5px; */
      margin-top: 6px;
      /* margin-bottom: 24.5px; */
    }
  }

  ${below.md} {
    column-count: 1;
    font-size: 18px;
    line-height: 30px;
  }
`

const Paragraph = styled(BlockContent)`
  column-count: 2;
`

const TwoColumnTextBlock = ({ block, block: { title, text, reducePadding } }) => (
  <OuterContainer>
    <ContentWrapper reducePadding={reducePadding}>
      <TitlehWrapper>
        <Title blocks={title || []} />
      </TitlehWrapper>
      <ParagraphWrapper>
        <Paragraph blocks={text || []} />
      </ParagraphWrapper>
    </ContentWrapper>
  </OuterContainer>
)

export default TwoColumnTextBlock
