import React from 'react'
import styled from 'styled-components'
import { below } from '../styles/abstracts/breakpoints'
import BlockContent from '../components/block-content'

const Root = styled.div`
  background-color: ${props => props.backgroundColor.hex};
  display: flex;
  font-family: Source Sans Pro;
  flex-direction: column;
  padding: ${props => props.noPadding ? '50px 0px 35px 0px' : '125px'};

  ${below.md} {
    margin: 0px;
    padding: 20px;
  }
`
const HeaderText = styled.div`
  font-size: 38px;
  margin: auto;
  font-weight: bold;
  margin-bottom: 25px;

  ${below.md} {
    margin: 0px;
    margin-bottom: 25px;
  }
`
const BodyText = styled.div`
  font-size: 18px;
  font-weight: 300;
  text-align: ${props => props.leftJustify ? 'left' : 'center'};
  width: 55%;
  margin: auto;
  ${below.lg} {
    width: 75%;
  }
  ${below.md} {
    margin: 0px;
    text-align: left;
    width: 95%;
    font-size: 18px;
    line-height: 30px;
  }
`
const InfoBlock1 = ({ block, block: { header, body, backgroundColor, leftJustify, noPadding, addAnchor } }) => {
  return (
    <Root id={addAnchor && addAnchor} backgroundColor={backgroundColor} noPadding={noPadding}>
      <HeaderText>{header}</HeaderText>
      <BodyText leftJustify={leftJustify}>
        <BlockContent blocks={body || []} />
      </BodyText>
    </Root>
  )
}

export default InfoBlock1
