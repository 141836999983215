import React from 'react'
import styled from 'styled-components'
import { above, below } from '../styles/abstracts/breakpoints.js'
import BlockContent from '../components/block-content'

const BlockWrapper = styled.div`
  display: flex;
  background-color: ${props => props.theme.color.white};
  justify-content: space-between;
  @media (max-width: 900px) {
    flex-direction: column;
  }
`
const BodyText = styled.div`
  display: block;
  position: relative;
  font-size: 20px;
  font-weight: 300;
  text-align: left;
  width: 100%;
  float: left;
  letter-spacing: -0.24px;
  line-height: 32px;
  margin-bottom: 15px;

  ${below.md} {
    text-align: left;
    width: 95%;
  }
`
const ImageBlock = styled.div`
  display: inline-block;
  width: 50%;
  height: 500px;
  max-width: 100%;
  @media (max-width: 900px) {
    width: 100%;
    height: 450px;
  }
  @media (max-width: 700px) {
    width: 100%;
    height: 400px;
  }
  @media (max-width: 500px) {
    width: 100%;
    height: 350px;
  }
`
const TextWrapper = styled.div`
  display: inline-block;
  position: relative;
  font-family: ${props => props.theme.font.family.base};
  font-size: 21px;
  flex-direction: column;
  padding-top: 30px;
  padding-bottom: 73px;
  padding-left: 50px;
  padding-right: 50px;
  width: calc(50% - 100px);
  max-width: calc(800px);

  ${below.lg} {
    padding-right: 62.5px;
    padding-bottom: 62.5px;
  }

  @media (max-width: 900px) {
    margin: 0px;
    padding-top: 15px;
    padding-bottom: 81px;
    padding-left: 50px;
    padding-right: 50px;
    max-width: calc(100% - 100px);
    width: calc(100% - 100px);
  }

  @media (max-width: 823px) {
    padding-right: 40px;
    padding-bottom: 40px;
  }
  @media (max-width: 785px) {
    margin-left: 20px;
  }
  ${below.md} {
    margin: 0px;
    padding-top: 15px;
    padding-bottom: ${props => props.headlineText ? '25px' : '81px'};
    padding-left: 20px;
    padding-right: 20px;
    max-width: calc(100% - 40px);
    font-size: 18px;
    width: calc(100% - 40px);
  }

  ${above.xxl} {
    margin-right: auto;
  }
`
const ImageWrapper = styled.div`
  width: 100%;
  margin: auto;
  height: 100%;
  background-image: url(${props => props.image});
  background-repeat: no-repeat;
  background-size: cover;
  ${below.md} {
    /* background-size: contain; */
  }
`
const HeadLineText = styled.div`
  display: flex;
  align-items: center; 
  justify-content: center; 
  font-size: 24px; 
  font-weight: bold; 
  color: #333;
  margin: 0; 
  padding: 20px;
  text-align: center; 
  width: 50%; 

  // Responsive adjustments
  @media (max-width: 900px) {
    font-size: 20px; // Smaller font for smaller screens
    width: 95%;
  }
`;

const Paragraph = styled(BlockContent)``

const TwoColumnTextImage = ({ block, block: { image, bodyText, headlineText } }) => {
  return (
    <BlockWrapper>
      <ImageBlock>
        <ImageWrapper image={image.asset.url} />
      </ImageBlock>
      {headlineText ? <HeadLineText>{headlineText}</HeadLineText> : 
      <TextWrapper>
        <Paragraph blocks={bodyText || []} />
      </TextWrapper>}
    </BlockWrapper>
  )
}

export default TwoColumnTextImage
