import { Link } from 'gatsby'
import React from 'react'
import styled from 'styled-components'
import { above, below } from '../styles/abstracts/breakpoints'
import { MdMailOutline } from 'react-icons/md'

const OuterBanner = styled.div`
  background-color: ${props => (props.whiteBackground ? 'white' : '#272727')};
  height: 275px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Source Sans Pro;
  position: relative;
  padding-left: 50px;
  a:hover,
  a:visited,
  a:link,
  a:active {
    text-decoration: none;
  }

  @media (max-width: 785px) {
    align-items: left;
    padding-left: 20px;
    justify-content: left;
    height: 281px;
    padding-top: 20px;
    padding-bottom: 20px;
  }
`

const InnerContainer = styled.div`
  display: flex;

  ${above.md} {
    align-items: center;
  }

  @media (max-width: 785px) {
    flex-direction: column;
  }
`
const Button = styled.button`
  background-color: #ffbc00;
  width: 160px;
  height: 40px;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 15px;
  border: none;
  margin-top: 15px;
  text-decoration: none;
  &:hover {
    background-color: rgba(255, 187, 0, 0.89);
  }
  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
    outline: 0;
  }
  cursor: pointer;
`
const Button2 = styled(Button)`
  margin-top: 0px;
`

const ButtonText = styled.div`
  font-weight: 600;
  font-size: 15px;
  text-decoration: none;
  color: black;
`
const InnerText = styled.div`
  display: flex;
  width: 455px;
  flex-direction: column;
  @media (max-width: 785px) {
    /* margin-left: 20px; */
    margin-bottom: 0px;
    width: 90%;
  }
`

const InnerText2 = styled(InnerText)`
  width: auto;
`

const HeaderText = styled.h2`
  font-size: 38px;
  color: white;
  font-weight: bold;
  margin-top: 0px;
  margin-bottom: 24px;
  ${below.md} {
    margin-bottom: 20px;
  }
`

const HeaderText2 = styled(HeaderText)`
  margin-bottom: 0px;
  color: ${props => (props.whiteBackground ? '#272727' : 'white')};
`
const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  ${above.md} {
    padding-left: 30px;
  }
`
const InputFields = styled.div`
  ${below.lg} {
    width: 70%;
  }
`
const StyledInput = styled.input`
  padding: 5px;
  width: calc(100% - 40px);
  font-size: 0.9rem;
  margin-top: 2px;
  margin-bottom: 2px;
  margin-right: 5px;
  color: ${props => props.theme.color.grey.dark};
  border: 1px solid;
  border-radius: 3px;
  &:focus {
    outline-color: ${props => props.theme.color.yellow};
    border-style: solid;
  }
`
const MailIcon = styled(MdMailOutline)`
  font-size: 18px;
  top: 3px;
  position: relative;
`
const form = (
  <form
    action="https://eastvanbjj.perfectmind.com/19331/Contacts/Signup"
    autoComplete="on"
    method="post"
    onsubmit="return formCheck(this); setCookie()"
  >
    <input name="signupId" type="hidden" value="e4316568-1425-4044-8e1f-f8db88c943b2" />{' '}
    <input id="ReferralCode" name="ReferralCode" type="hidden" />
    <input
      name="CampaignActivity.Campaign"
      type="hidden"
      name="28e7a8fa-cbf7-41f3-839c-218f363da0a4"
      value="286c7c3d-f6db-49b4-b000-5ce44c2b37ce"
    />
    <input id="stepToGo" name="stepToGo" type="hidden" value="CheckoutComplete" />
    <input id="goBackUrl" name="goBackUrl" type="hidden" value="/newsletter-signup-thank-you" />
    <InputFields>
      <StyledInput id="FirstName" name="FirstName" placeholder="First Name*" type="text" />
      <StyledInput id="LastName" name="LastName" placeholder="Last Name*" type="text" />
      <StyledInput id="Email" name="Email" placeholder="Email*" type="text" />
    </InputFields>
    <Button type="submit">
      SIGN UP <MailIcon />
    </Button>
  </form>
)

const NewsletterForm = ({
  block,
  block: { newsletterFormHeader, buttonText, whiteBackground }
}) => (
  <OuterBanner whiteBackground={whiteBackground}>
    <InnerContainer>
      <InnerText2>
        <HeaderText2 whiteBackground={whiteBackground}>{newsletterFormHeader}</HeaderText2>
      </InnerText2>
      <FormContainer>{form}</FormContainer>
    </InnerContainer>
  </OuterBanner>
)

export default NewsletterForm
