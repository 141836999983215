import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { above, below } from '../styles/abstracts/breakpoints.js'
import map from '../images/map.png'
import allianceYellow from '../images/alliance-yellow.png'

const BlockWrapper = styled.div`
  display: flex;
  background-color: ${props => props.theme.color.white};
  justify-content: space-between;

  @media (max-width: 900px) {
    flex-direction: column;
  }
`

const TextWrapper = styled.div`
  display: inline-block;
  position: relative;
  font-family: ${props => props.theme.font.family.base};
  flex-direction: column;
  padding-top: 125px;
  padding-bottom: 73px;
  padding-left: 50px;
  padding-right: 50px;
  width: calc(50% - 100px);
  max-width: calc(800px);

  ${below.lg} {
    padding-right: 62.5px;
    padding-bottom: 62.5px;
  }

  @media (max-width: 900px) {
    margin: 0px;
    padding-top: 81px;
    padding-bottom: 81px;
    padding-left: 50px;
    padding-right: 50px;
    max-width: calc(100% - 100px);
    width: calc(100% - 100px);
  }

  @media (max-width: 823px) {
    padding-right: 40px;
    padding-bottom: 40px;
  }
  @media (max-width: 785px) {
    margin-left: 20px;
  }
  ${below.md} {
    margin: 0px;
    padding-top: 81px;
    padding-bottom: 81px;
    padding-left: 20px;
    padding-right: 20px;
    max-width: calc(100% - 40px);
    width: calc(100% - 40px);
  }

  ${above.xxl} {
    margin-right: auto;
  }
`

const HeaderText = styled.h2`
  font-size: 38px;
  display: block;
  float: left;
  font-weight: bold;
  margin-top: 0px;
  margin-bottom: 25px;
  width: 100%;
  min-width: 300px;
  ${below.md} {
    margin: 0px;
    margin-bottom: 25px;
  }
`

const BodyText = styled.div`
  display: block;
  position: relative;
  font-size: 18px;
  font-weight: 300;
  text-align: left;
  width: 100%;
  float: left;
  letter-spacing: -0.24px;
  line-height: 32px;
  margin-bottom: 15px;

  ${below.md} {
    text-align: left;
    width: 95%;
    font-size: 18px;
    line-height: 30px;
  }
`

const ScheduleButton = styled.button`
  background-color: #ffbc00;
  width: 160px;
  height: 40px;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 15px;
  border: none;
  margin-top: 15px;
  text-decoration: none;
  &:hover {
    background-color: rgba(255, 187, 0, 0.89);
  }
  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
    outline: 0;
  }
`
const MapWrapper = styled.div`
  width: 50%;
  @media (max-width: 900px) {
    width: 100%;
  }
`

const MapBlock = styled.div`
  display: inline-block;
  position: relative;
  background-image: url(${map});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 500px;
  max-width: 100%;
  float: right;
`

const AlliancePin = styled.img`
  position: relative;
  height: 50px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

const StyledLink = styled(Link)``

const Contact = ({ block, block: { phoneNumber, fullAddress, mapsUrl, header, bodyText } }) => {
  return (
    <BlockWrapper>
      <MapWrapper>
        <a href={mapsUrl} target='_blank' style={{}}>
          <MapBlock>
            <AlliancePin src={allianceYellow} />
          </MapBlock>
        </a>
      </MapWrapper>
      <TextWrapper>
        <HeaderText>{header || null}</HeaderText>
        <BodyText>
          <a
            href={`tel:+${phoneNumber}`}
            style={{ color: 'black', fontWeight: 'bold', textDecoration: 'none' }}
          >
            {phoneNumber}
          </a>
        </BodyText>
        <a href={mapsUrl} target='_blank' style={{ textDecoration: 'none', color: 'black' }}>
          <BodyText>{fullAddress}</BodyText>
        </a>
        <BodyText>{bodyText || null}</BodyText>
        <StyledLink to='/schedule/'>
          <ScheduleButton>SEE SCHEDULE</ScheduleButton>
        </StyledLink>
      </TextWrapper>
    </BlockWrapper>
  )
}

export default Contact
