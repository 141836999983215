import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { above, below } from '../styles/abstracts/breakpoints.js'
import placeHolder from '../images/placeHolder.jpg'
import PolygonContainer from '../components/polygon-container'

const OuterContainer = styled.div`
  z-index: 1;
  width: 100%;
`

const ImageWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 10px;
  justify-content: center;
  ${below.md} {
    padding: 33px;
  }
`

const ImageHelper = styled.div`
  flex: 1;
  padding: 10px;
  min-width: 208px;
  position: relative;
  text-align: center;
  color: ${props => props.theme.font.color.white};
  ${above.sm} {
    margin: ${props => {
      if (props.props.numberOfImages === 1) {
        return 'auto'
      }
    }};
  }

  max-width: ${props => {
    if (props.props.numberOfImages <= 2) {
      return 'calc(50% - 2em);'
    } else {
      return 'calc(33.333% - 2em);'
    }
  }};

  width: ${props => {
    if (props.props.numberOfImages <= 2) {
      return 'calc(50% - 2em);'
    } else {
      return 'calc(33.333% - 2em);'
    }
  }};

  ${above.md} {
    padding: 1em;
    min-width: ${props => {
      if (props.props.numberOfImages <= 2) {
        return 'none'
      } else {
        return 'calc(33.333% - 2em);'
      }
    }};
    max-width: ${props => {
      if (props.props.numberOfImages <= 2) {
        return '705px'
      } else {
        return 'calc(33.333% - 2em);'
      }
    }};
  }

  ${below.md} {
    min-width: calc(50% - 2em);
  }

  ${below.sm} {
    min-width: calc(100% - 1em);
  }
`

const Image = styled.img`
  width: 100%;
  max-width: 705px;
  height: auto;
  filter: brightness(50%);
  &:hover {
    filter: brightness(100%);
    transition: 0.3s;
  }
`

const CenteredImageText = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: ${props => props.theme.font.family.base};
  color: ${props => props.theme.font.color.white};
`

const HeaderImageText = styled.h2`
  margin-bottom: 0px;
  margin-top: 0px;
  font-weight: bold;
  font-size: 28px;
  color: ${props => props.theme.font.color.white};
`

const SubtitleImageText = styled.h3`
  margin-bottom: 0px;
  margin-top: 0px;
  font-size: 18px;
  font-family: ${props => props.theme.font.family.base};
  font-weight: 600;
  color: ${props => props.theme.font.color.white};
`

const Block = props => {
  const {
    blockProps,
    blockProps: { _rawImage },
    imagesType
  } = props

  const imageSrc = _rawImage ? _rawImage.asset.url : placeHolder
  const subtitleImageText = blockProps[`${imagesType}` + 'Title']
  let headerImageText
  let blockUrl = '/'
  let slug = ''
  let programTitle = ''

  if (imagesType === 'instructor') {
    headerImageText = blockProps.name
    blockUrl = '/instructor/'
    let splitName = headerImageText.toLowerCase().split(' ')
    slug = splitName.join('-')
    blockUrl += slug
  }

  if (imagesType === 'program') {
    headerImageText = blockProps.programType
    programTitle = blockProps.programTitle
    if (programTitle.toLowerCase().indexOf('age') >= 0) {
      slug = 'kids'
    } else {
      let splitName = programTitle.toLowerCase().split(' ')
      slug = splitName.join('')
    }
    blockUrl += slug
  }

  return (
    <ImageHelper props={props}>
      <Link to={blockUrl}>
        <Image src={imageSrc} />
        <CenteredImageText>
          <HeaderImageText>{headerImageText}</HeaderImageText>
          <SubtitleImageText>{subtitleImageText}</SubtitleImageText>
        </CenteredImageText>
      </Link>
    </ImageHelper>
  )
}

const ImageGallery = ({
  block,
  block: {
    images,
    _type,
    greyWrapperBackground,
    greyBackgroundCoverFromTop,
    lightGreyBackgroundCoverFromTop,
    paddingBottom,
    distanceFromTop
  }
}) => {
  const imagesType = images[0]._type
  const imagesArray = images
  const numberOfImages = imagesArray.length

  var blocks = imagesArray.map((blockProps, i) => {
    return (
      <Block
        blockProps={blockProps}
        imagesType={imagesType}
        numberOfImages={numberOfImages}
        key={i}
      />
    )
  })

  return (
    <PolygonContainer
      greyWrapperBackground={greyWrapperBackground}
      paddingBottom={paddingBottom}
      distanceFromTop={distanceFromTop[0]}
      greyBackgroundCoverFromTop={greyBackgroundCoverFromTop[0]}
      lightGreyBackgroundCoverFromTop={lightGreyBackgroundCoverFromTop[0]}
      childComponent={
        <OuterContainer>
          <ImageWrapper>{blocks}</ImageWrapper>
        </OuterContainer>
      }
    />
  )
}

export default ImageGallery
