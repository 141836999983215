import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { above, below } from '../styles/abstracts/breakpoints.js'
import BlockContent from '../components/block-content'

const OuterContainer = styled.div`
  background-color: ${props => props.theme.color.white};
  display: flex;
  display: -webkit-flex;
  display: -ms-flexbox;
  flex-wrap: wrap;
  overflow: visible;

  ${below.md} {
    width: 100%;
    overflow: hidden;
  }

  ${below.xxl} {
    flex-direction: column-reverse;
  }
`

const ContentWrapper = styled.div`
  flex-wrap: wrap;
  color: ${props => props.theme.font.color.grey.medium};
  font-family: ${props => props.theme.font.family.base};
  font-size: 21px;
  font-weight: 300;

  padding: 3.125em;
  padding-left: 6.25em;
  padding-right: 6.25em;
  ${props => (props.reducePadding ? 'padding-top: 0.5em' : '')};
  ${below.md} {
    padding-left: 1.25em;
    padding-right: 1.25em;
  }
`

const TitlehWrapper = styled.div`
  margin-bottom: 25px;
`

const Title = styled(BlockContent)`
  letter-spacing: -0.51px;
`

const BlocksWrapper = styled.div``

const BlockOne = styled.div`
  display: inline-block;
  letter-spacing: -0.24px;
  float: left;
  width: calc(50% - 25px);
  margin-right: 25px;
  h2 {
    margin: 0px;
  }
  p {
    margin-top: 0px;
  }
  img {
    max-width: 225px;
    ${below.md} {
      width: 100%;
      max-width: none;
    }
  }
  figure {
    float: left;
    margin-right: 40px;
    margin-bottom: 10px;
    margin-top: 6px;
    ${below.md} {
      margin: 0px;
    }
  }
  ${below.md} {
    width: 100%;
  }
`

const BlockTwo = styled(BlockOne)`
  float: right;
  margin-right: 0px;
  margin-left: 25px;
`

const Paragraph = styled(BlockContent)``

const Button = styled.div`
  margin-left: 50px;
  margin-top: 45px;
  background-color: #ffbc00;
  width: 198px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    background-color: rgba(255, 187, 0, 0.89);
    transition: 0.3s;
  }
  ${below.md} {
    margin-left: 0px;
    margin-top: 20px;
  }
`
const ButtonText = styled.div`
  font-weight: 600;
  font-size: 15px;
  text-decoration: none;
  color: black;
`
const StyledExternalLink = styled.a`
  text-decoration: none;
`
const ButtonWrapper = styled.div`
  margin-left: 0px;
`
const TwoColumnImageTextBlock = ({
  block,
  block: { title, textBlockOne, textBlockTwo, reducePadding, ctaPath, ctaText, ctaPath2, ctaText2 }
}) => (
  <OuterContainer>
    <ContentWrapper reducePadding={reducePadding}>
      <TitlehWrapper>
        <Title blocks={title || []} />
      </TitlehWrapper>
      <BlocksWrapper>
        <BlockOne>
          <Paragraph blocks={textBlockOne || []} />
        </BlockOne>
        <BlockTwo>
          {ctaPath && ctaText ? (
            <ButtonWrapper>
              <StyledExternalLink href={ctaPath} target="_blank">
                <Button>
                  <ButtonText>{ctaText}</ButtonText>
                </Button>
              </StyledExternalLink>
              {ctaPath2 ? (
                <StyledExternalLink href={ctaPath2} target="_blank">
                  <Button>
                    <ButtonText>{ctaText2}</ButtonText>
                  </Button>
                </StyledExternalLink>
              ) : null}
            </ButtonWrapper>
          ) : (
            <Paragraph blocks={textBlockTwo || []} />
          )}
        </BlockTwo>
      </BlocksWrapper>
    </ContentWrapper>
  </OuterContainer>
)

export default TwoColumnImageTextBlock
