import React from 'react'
import styled from 'styled-components'
import { below } from '../styles/abstracts/breakpoints.js'
import BlockContent from '../components/block-content'

const OuterContainer = styled.div`
  background-color: ${props => props.theme.color.white};
  display: flex;
  display: -webkit-flex;
  display: -ms-flexbox;
  flex-wrap: wrap;
  overflow: visible;

  ${below.md} {
    width: 100%;
    overflow: hidden;
  }

  ${below.xxl} {
    flex-direction: column-reverse;
  }
`

const ContentWrapper = styled.div`
  flex-wrap: wrap;
  color: ${props => props.theme.font.color.grey.medium};
  font-family: ${props => props.theme.font.family.base};
  font-size: 21px;
  font-weight: 300;
  letter-spacing: -0.28px;
  padding: 3.125em;
  line-height: 2em;

  ${below.md} {
    padding-left: 1.25em;
    padding-right: 1.25em;
  }
`

const TextBlock = ({ block, block: { text } }) => (
  <OuterContainer>
    <ContentWrapper>
      <BlockContent blocks={text || []} />
    </ContentWrapper>
  </OuterContainer>
)

export default TextBlock
