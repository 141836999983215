import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { SimpleSwiper } from '../elements'
import { above, below, breakpointIntegers } from '../styles/abstracts/breakpoints.js'

const Root = styled.div`
  width: 100%;
  font-family: ${props => props.theme.font.family.base};
  margin-bottom: -5px;
  overflow: hidden;
  display: inline-block;
  position: relative;
  background-color: #ece8e7;
  z-index: -5;

  ${above.xxl} {
    height: 1200px;
  }

  ${below.xxl} {
    height: 900px;
  }

  ${below.md} {
    height: 875px;
  }
`

const SwiperWrapper = styled.div`
  z-index: -2;
  height: 700px;
  width: 100%;
  position: absolute;
  top: 140px;
  left: 0;

  ${above.xxl} {
    top: 190px;
    height: 1400px;
    .swiper-container {
      height: 1400px;
    }
    .swiper-wrapper {
      height: 1400px;
    }
    .swiper-slide {
      height: 1400px;
    }
    .ImageDiv {
      height: 1400px;
    }
  }

  ${above.xl} {
    height: 1200px;
    .swiper-container {
      height: 1200px;
    }
    .swiper-wrapper {
      height: 1200px;
    }
    .swiper-slide {
      height: 1200px;
    }
    .ImageDiv {
      height: 1200px;
    }
  }

  ${above.lg} {
    height: 1000px;
    .swiper-container {
      height: 1000px;
    }
    .swiper-wrapper {
      height: 1000px;
    }
    .swiper-slide {
      height: 1000px;
    }
    .ImageDiv {
      height: 1000px;
    }
  }

  ${below.sm} {
    top: 100px;
    .swiper-container {
      height: 700p;
    }
    .swiper-wrapper {
      height: 700px;
    }
    .swiper-slide {
      height: 700px;
    }
    .ImageDiv {
      height: 700px;
    }
  }
  .swiper-pagination-bullet {
    background: ${props => props.theme.color.grey.dark};
  }
  .swiper-pagination-bullet-active {
    background: ${props => props.theme.color.yellow};
  }
`

const TextContainer = styled.div`
  padding-top: 275px;
  margin-left: 50px;
  display: flex;
  flex-direction: column;
  width: 670px;
  width: 40%;
  height: 146px;
  position: absolute;
  font-family: ${props => props.theme.font.family.base};

  ${below.md} {
    width: 50%;
    margin-left: 20px;
    padding-top: 145px;
  }

  ${below.sm} {
    width: 90%;
  }
`
const HeaderText1 = styled.h1`
  font-size: 58px;
  color: black;
  font-weight: bold;
  letter-spacing: -0.77px;
  margin-top: 0px;
  margin-bottom: 0px;
  ${below.lg} {
    font-size: 35px;
  }
`
const HeaderText2 = styled.h1`
  font-size: 58px;
  color: black;
  font-weight: bold;
  background-color: #ffbc00;
  letter-spacing: -0.77px;
  max-width: fit-content;
  min-width: fit-content;
  padding-left: 0.25em;
  padding-right: 0.25em;
  margin-top: 0px;
  margin-bottom: 0px;
  ${below.lg} {
    font-size: 35px;
  }
`
const SubHeaderText = styled.div`
  font-size: 21px;
  color: #474747;
  letter-spacing: -0.28px;
  line-height: 30px;
  margin-top: 1.25em;
  max-width: 28.79em;

  ${below.lg} {
    font-size: 18px;
  }
`
const PlaceHolder = styled.div`
  height: 875px;
  background-color: #ece8e7;
`
const HeroImage = ({
  block: {
    heroImages,
    mobileHeroImages,
    heroTitle,
    heroSubHeader,
    heroBody,
    swiperTopPaddingMobile,
    swiperBackgroundColor
  }
}) => {
  const [hasLoaded, setHasLoaded] = useState(false)
  const [width, setWidth] = useState(typeof window !== 'undefined' ? window.innerWidth : 1920)
  const responsiveSlides =
    width > breakpointIntegers.md ? heroImages.slides : mobileHeroImages.slides
  const [heroSlides, setHeroSlides] = useState(responsiveSlides)

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth)
      return setHeroSlides(responsiveSlides)
    }
    if (!hasLoaded) {
      setWidth(window.innerWidth)
      setHasLoaded(true)
    }

    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  })

  return hasLoaded ? (
    <>
      <TextContainer>
        <HeaderText1>{heroTitle}</HeaderText1>
        <HeaderText2>{heroSubHeader}</HeaderText2>
        <SubHeaderText>{heroBody}</SubHeaderText>
      </TextContainer>
      <Root>
        <SwiperWrapper>
          <SimpleSwiper
            images={heroSlides}
            topPaddingMobile={swiperTopPaddingMobile}
            backgroundColor={swiperBackgroundColor}
          />
        </SwiperWrapper>
      </Root>
    </>
  ) : (
    <>
      <TextContainer>
        <HeaderText1>{heroTitle}</HeaderText1>
        <HeaderText2>{heroSubHeader}</HeaderText2>
        <SubHeaderText>{heroBody}</SubHeaderText>
      </TextContainer>
      <Root>
        <SwiperWrapper>
          <PlaceHolder />
        </SwiperWrapper>
      </Root>
    </>
  )
}

export default HeroImage
